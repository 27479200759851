import { Component, OnInit, Input } from "@angular/core";
import { PatientRecordService } from "../patient-record.service";
import { MedicalRecordService } from "./medical-record.service";
import { TranslateService } from "@ngx-translate/core";
import { BreadcrumbService } from "src/app/theme/components/breadcrumb/breadcrumb.service";
import { Helpers } from "src/app/services/app.helpers";
import * as fhir from "fhir-stu3";
import { IRecord, ICustomObservation, IObservationCategory, IRecordItem } from "./medical-record.model";
import * as moment from "moment";
import { environment } from "src/environments/environment";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastService } from "src/app/core/toast.service";
import { observationToCategoryMapping } from "src/app/core/models/valueset.model";
import { Role } from "../../../core/enum/Role";
import { AuthenticationService } from "../../../services/authentication.service";
import enLocale from "@angular/common/locales/en-GB";
import elLocale from "@angular/common/locales/el";
import { registerLocaleData } from "@angular/common";
import { GoogleAnalyticsService } from "src/app/services/google-analytics.service";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "app-medical-record",
  templateUrl: "./medical-record.component.html",
  styleUrls: ["./medical-record.component.scss"],
})
export class MedicalRecordComponent implements OnInit {
  public patientRestrictedAccess!: any;

  patientParticipationData: any;

  showEditParticipationPopup = false;
  records: Array<IRecord> = [];
  addObservationRecords: Array<IRecord> = [];
  addObservationDropDownRecords: Array<{ id: string; name: string; label: string }> = [];
  lastObservations: Array<{
    Id: string;
    ObservationName: string;
    Value: { component: fhir.ObservationComponent; date: string };
  }> = [];
  addObservationForm!: FormGroup;
  isAddObservationVisisble = false;
  isInfoPopupVisible = false;
  selectedRecord = {};
  // showComponent: "info" | "add_procedures" | "add_vaccines" | "add_family_history" | "add_allergies" | "add_medication" | "add_socialHistory";
  showComponent!: string;
  selectedAttributes: Array<{ id: string; text: string }> = [];
  patientId!: string;
  observationCategories: Array<IObservationCategory> = [];
  excludedCategories: Array<IObservationCategory> = [];
  filteredExcludedCategories: Array<IObservationCategory> = [];
  selectedCategories: Array<IObservationCategory> = [];
  canAddObservation: { [key: string]: boolean } = {};
  selectedObservationCode!: { id: string; name: string; label: string };
  chartInput: IRecordItem | null = null;
  showChartPopup = false;
  formSubmitting = false;
  maxObservationDate = new Date();

  editComponentCategory!: string;
  selectedItemForEdit: any;
  isEditPopupVisible = false;
  activeTab = "patient-file";

  @Input() showCategories!: Array<string>;
  patientGender!: string | null;
  locale: any;

  conditions: any[] = [];

  isObservationListVisible: boolean = false;
  observationList: any[] = [];

  constructor(
    private patientRecordService: PatientRecordService,
    private medicalRecordService: MedicalRecordService,
    private translationService: TranslateService,
    private breadcrumbService: BreadcrumbService,
    private helpers: Helpers,
    private fb: FormBuilder,
    private toastService: ToastService,
    private authenticationService: AuthenticationService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private httpClient: HttpClient
  ) {}

  ngOnInit() {
    this.getMedicalConditions();
    registerLocaleData(enLocale, "en");
    registerLocaleData(elLocale, "el");
    this.locale = this.translationService.currentLang;

    this.translationService.onLangChange.subscribe(() => {
      this.locale = this.translationService.currentLang;
    });

    this.patientRestrictedAccess = this.authenticationService.hasRole(Role.Doctor);
    this.patientId = this.patientRecordService.patientId!;

    this.createAddObservationForm();
    this.getPatientParticipationData();
    this.getMedicalRecord();
    this.updateBreadCrumps();

    this.translationService.onLangChange.subscribe(() => {
      this.updateBreadCrumps();
      this.updateAddObservationDropDownRecordsLables();
    });
  }

  getMedicalConditions() {
    this.httpClient.get<any[]>(`${environment.serverPath}/api/page/medical-conditions`).subscribe((res) => {
      this.conditions = res;
      this.conditions.forEach((z) => (z.isSelected = false));
      this.getPatientMainConditions();
    });
  }

  getPatientMainConditions() {
    this.httpClient
      .get<any[]>(`${environment.serverPath}/api/patient-main-conditions/patient/${this.patientId}`)
      .subscribe((res) => {
        for (const item of res) {
          const c = this.conditions.find((z) => z.code === item.conditionCode);
          if (c) {
            c.isSelected = true;
          }
        }
      });
  }

  saveMainConditions() {
    this.httpClient
      .post<any>(
        `${environment.serverPath}/api/patient-main-conditions/update/${this.patientId}`,
        this.conditions.filter((x) => x.isSelected).map((x) => x.code)
      )
      .subscribe((res) => {
        this.toastService.successMessage("The main conditions were saved successfully");
      });
  }

  activateTab(tabName: string) {
    this.activeTab = tabName;
  }

  createAddObservationForm() {
    this.addObservationForm = this.fb.group({
      observationCode: [this.selectedObservationCode ? this.selectedObservationCode : "", Validators.required],
      observationValue: [0, Validators.required],
      observationDate: [new Date(), Validators.required],
    });
  }

  updateUi() {
    this.getProfileData();
  }

  updateBreadCrumps() {
    if (this.patientRestrictedAccess) {
      this.breadcrumbService.data = [
        // { label: this.translationService.instant("sidebar.Dashboard"), url: "/dashboard" },
        { label: this.translationService.instant("breadcrump.Patients"), url: `/patients` },
        {
          label: this.patientRecordService.patientFullName,
          // +
          // " - " +
          // this.translationService.instant("breadcrump.MedicalRecord"),
          url: `/patients/patient-record/${this.patientRecordService.patientId}/medical-record`,
        },
      ];
    } else {
      this.breadcrumbService.data = [
        {
          label: this.patientRecordService.patientFullName,
          //  +
          // " - " +
          // this.translationService.instant("breadcrump.MedicalRecord"),
          url: `/patients/patient-record/${this.patientRecordService.patientId}/medical-record`,
        },
      ];
    }
  }

  translate(s: string) {
    return this.translationService.instant(s);
  }

  async getMedicalRecord() {
    this.medicalRecordService.getMedicalRecordCategories().subscribe(async (data) => {
      this.records = data.records;
      this.records.find((x) => x.id === "general")!.canAdd = false; // change from true in order to hide edit icon
      this.records.find((x) => x.id === "blood")!.canAdd = false;
      // this.records.find((x) => x.id === "body-measurements").canAdd = true;
      let height;
      // this.records.forEach(element => {

      //   if(element.id='general'){
      //     var tmp=element;
      //     console.log(element)
      //   }
      // });
      if (this.showCategories) {
        this.records = this.records.filter((record) => this.showCategories.includes(record.id));
      }

      this.addObservationRecords = data.records.filter(
        (x) => x.id === "general" || x.id === "blood" || x.id === "body-measurements"
      );
      let temp = this.addObservationRecords.map((x) => x.items).reduce((acc, val) => acc!.concat(val!), []);

      for (let i = 0; i < temp!.length; i++) {
        if (temp![i].canAdd) {
          this.canAddObservation[temp![i]!.id!] = true;
        } else this.canAddObservation[temp![i]!.id!] = false;
      }

      this.updateAddObservationDropDownRecordsLables();
      await this.getExcludedObservationCategories();
      this.getObservations();
      this.getPatientReports();
      this.getPatientProcedures();
      this.getPatientVaccines();
      this.getPatientFamilyMembers();
      this.getPatientAllergies();
      this.getPatientMedications();
      this.getPatientConditions();
      this.getPatientHospitalizations();
      this.getPatientSocialHistory();
      this.updateUi();
    });
  }

  private updateAddObservationDropDownRecordsLables() {
    this.addObservationDropDownRecords = [];
    this.addObservationRecords.forEach((x, outerIndex) => {
      x.items!.forEach((z, index) => {
        z.label = this.translationService.instant(z.name!);
        if (index === 0 && outerIndex === 0) {
          this.addObservationForm.get("observationCode")!.setValue(z);
        }

        (this.addObservationDropDownRecords as any[]).push({
          id: z.id,
          label: z.label,
          name: z.name,
        });
      });
    });
  }

  edit(record: IRecord) {}

  getProfileData() {
    if (this.showCategories) return;
    const profile = this.patientRecordService.patientInfo;
    (this.records as any[]).find((x) => x.id === "general").items.find((x: any) => x.id === "age").value =
      profile.Age !== undefined ? profile.Age : "";
    (this.records as any[]).find((x) => x.id === "general").items.find((x: any) => x.id === "gender").value =
      profile.Gender ? this.translationService.instant(profile.Gender) : "";
    this.patientGender = profile.Gender ? this.translationService.instant(profile.Gender) : null;
  }

  async getObservations() {
    this.lastObservations = (await this.patientRecordService.getPatientLastObservations().toPromise()) as any;
    if (this.observationCategories && this.lastObservations && this.observationCategories.length !== 0) {
      this.lastObservations = this.lastObservations.filter(
        (x) =>
          this.observationCategories.some((y) => y.code === x.ObservationName) ||
          (this.records as any[])
            .find((x) => x.id === "body-measurements")
            ?.items?.some((z: any) => z.code === x.ObservationName)
      );
    }

    this.updateObservationData();
  }

  private updateObservationData() {
    if (this.lastObservations && Array.isArray(this.lastObservations)) {
      for (const x of this.lastObservations) {
        const record = this.getRecordByCode(x.ObservationName);

        if (record) {
          (record.fhirId = x.Id), (record.date = new Date(x.Value.date));
          record.dateStr = moment(record.date).format(environment.dateDefaultFormat);
          if (record.code === "55284-4") {
            if (!record.valueArr) record.valueArr = [];
            record.valueArr.push(x.Value.component.valueQuantity!.value!.toString());
            record.value =
              record.valueArr.join("/") +
              " " +
              (x.Value.component.valueQuantity && x.Value.component.valueQuantity.unit
                ? x.Value.component.valueQuantity.unit
                : "");
          } else {
            record.value =
              x.Value.component.valueQuantity && x.Value.component.valueQuantity.value
                ? x.Value.component.valueQuantity.value.toString()
                : "0" +
                  " " +
                  (x.Value.component.valueQuantity && x.Value.component.valueQuantity.unit
                    ? x.Value.component.valueQuantity.unit
                    : "");
          }
        }
      }
    }
  }

  private getRecordByCode(code: string) {
    for (let i = 0; i < this.records.length; i++) {
      const rec = this.records[i].items!.find((x) => x.id === code);

      if (rec) return rec;
    }

    return null;
  }

  addNewObservation() {
    try {
      this.formSubmitting = true;
      const formData = this.addObservationForm.getRawValue();
      const model: ICustomObservation = {
        observationCategory: this.getCategoryOfObservation(formData.observationCode.id),
        observationCategoryName: this.translationService.instant(
          `medicalRecord.category.${this.getCategoryOfObservation(formData.observationCode.id)}`
        ),
        observationCode: formData.observationCode,
        observationDate: formData.observationDate,
        observationUnit: this.getUnitOfObservation(formData.observationCode.id)!,
        observationValue: formData.observationValue,
      };
      // console.log(formData)
      // if(formData.observationCode.id==="3141-9-manual"){
      //   console.log('test test')

      // }
      // const bmimodel: ICustomObservation = {
      //   observationCategory: this.getCategoryOfObservation(formData.observationCode.id),
      //   observationCategoryName: this.translationService.instant(
      //     `medicalRecord.category.${this.getCategoryOfObservation(formData.observationCode.id)}`
      //   ),
      //   observationCode: formData.observationCode,
      //   observationDate: formData.observationDate,
      //   observationUnit: this.getUnitOfObservation(formData.observationCode.id),
      //   observationValue: formData.observationValue,
      // };

      this.medicalRecordService.addObservation(model, this.patientRecordService.patientId!).subscribe(
        (x) => {
          this.getMedicalRecord();
          this.formSubmitting = false;
          this.isAddObservationVisisble = false;
        },
        (err) => {
          this.formSubmitting = false;
          this.toastService.generalErrorMessage();
        }
      );
    } catch (err) {
      this.formSubmitting = false;
      console.log(err);
    }
  }

  getCategoryOfObservation(observationCode: string) {
    let category = observationToCategoryMapping.find((x) => x.observation === observationCode)!.category;
    return category;
  }

  getUnitOfObservation(observationCode: string) {
    let category = observationToCategoryMapping.find((x) => x.observation === observationCode)!.unit;

    return category;
  }

  openAddObservationPopup(item: IRecordItem) {
    this.selectedObservationCode = this.addObservationDropDownRecords.filter((x) => x.id === item.id)[0];
    this.createAddObservationForm();
    this.isAddObservationVisisble = true;
  }

  toggleEditPopup(
    value:
      | "add_general"
      | "add_blood"
      | "add_procedures"
      | "add_vaccines"
      | "add_family_history"
      | "add_allergies"
      | "add_medication"
      | "add_socialHistory"
      | "add_conditions"
      | "add_encounters"
  ) {
    this.selectedCategories = [];

    // if (value === "add_general" || value === "add_blood") this.filteredExcludedCategories = this.excludedCategories.map(x => ({ ...x, label: this.translationService.instant("medicalRecord.observationCode." + x.label) }));

    if (value === "add_general") {
      this.filteredExcludedCategories = this.excludedCategories
        .filter((x) => x.category === ("general" as string))
        .map((x) => ({ ...x, label: this.translationService.instant("medicalRecord.observationCode." + x.label) }));
    }
    if (value === "add_blood") {
      this.filteredExcludedCategories = this.excludedCategories
        .filter((x) => x.category === ("blood" as string))
        .map((x) => ({ ...x, label: this.translationService.instant("medicalRecord.observationCode." + x.label) }));
    }

    this.showComponent = value;
    this.isInfoPopupVisible = !this.isInfoPopupVisible;
  }

  toggleInfoPopup(attr: Array<{ id: string; text: string }>, data: object) {
    this.showComponent = "info";
    this.selectedRecord = { ...data };
    this.selectedAttributes = [...attr];
    this.isInfoPopupVisible = !this.isInfoPopupVisible;
  }

  getPatientProcedures() {
    if (this.showCategories && !this.showCategories.includes("procedures")) return;
    this.records.find((x) => x.id === "procedures")!.action = true;
    this.records.find((x) => x.id === "procedures")!.canAdd = true;
    this.patientRecordService.getPatientProcedures().subscribe(
      (procedures) => {
        this.records.find((x) => x.id === "procedures")!.attrForAction = [
          { id: "status", text: this.translate("medicalRecord.status") },
          { id: "code", text: this.translate("medicalRecord.procedure") },
          { id: "category", text: this.translate("medicalRecord.Category") },
          { id: "performedDateTime", text: this.translate("medicalRecord.datePerformed") },
          { id: "reasonCode", text: this.translate("medicalRecord.reason") },
          { id: "note", text: this.translate("medicalRecord.notes") },
        ];
        (this.records as any[]).find((x) => x.id === "procedures").items = procedures.map((procedure) => {
          return {
            id: procedure.id,
            name: procedure.code!.text,
            value: null,
            date: null,
            dateStr: procedure.performedDateTime,
            data: procedure,
          };
        });
      },
      (err) => {
        this.toastService.generalErrorMessage();
      }
    );
  }

  saveProcedure(procedure: any) {
    this.isInfoPopupVisible = !this.isInfoPopupVisible;
    this.patientRecordService.addPatientProcedure(procedure).subscribe(
      (x) => {
        if (!this.helpers.responseHasErrors(x, true)) {
          this.getPatientProcedures();
          this.toastService.successMessage(this.translationService.instant("medicalRecord.createSuccessProcedure"));
        }
      },
      (err) => {
        this.toastService.generalErrorMessage();
      }
    );
  }

  getPatientVaccines() {
    if (this.showCategories && !this.showCategories.includes("vaccines")) return;
    this.records.find((x) => x.id === "vaccines")!.action = true;
    this.records.find((x) => x.id === "vaccines")!.canAdd = true;
    this.patientRecordService.getPatientVaccines().subscribe(
      (vaccines) => {
        this.records.find((x) => x.id === "vaccines")!.attrForAction = [
          { id: "vaccineCode", text: this.translate("medicalRecord.vaccine") },
          { id: "date", text: this.translate("medicalRecord.date") },
          { id: "expirationDate", text: this.translate("medicalRecord.expirationDate") },
          { id: "note", text: this.translate("medicalRecord.notes") },
        ];
        (this.records as any[]).find((x) => x.id === "vaccines").items = vaccines.map((vaccine) => {
          return {
            id: vaccine.id,
            name: vaccine.vaccineCode.text,
            value: null,
            date: null,
            dateStr: vaccine.date,
            data: vaccine,
          };
        });
      },
      (err) => {
        this.toastService.generalErrorMessage();
      }
    );
  }

  saveVaccine(vaccine: any) {
    this.isInfoPopupVisible = !this.isInfoPopupVisible;
    this.patientRecordService.addPatientVaccine(vaccine).subscribe(
      (x) => {
        if (!this.helpers.responseHasErrors(x, true)) {
          this.getPatientVaccines();
          this.toastService.successMessage(this.translationService.instant("medicalRecord.createSuccessVaccine"));
        }
      },
      (err) => {
        this.toastService.generalErrorMessage();
      }
    );
  }

  getPatientFamilyMembers() {
    if (this.showCategories && !this.showCategories.includes("family_history")) return;
    this.records.find((x) => x.id === "family_history")!.action = true;
    this.records.find((x) => x.id === "family_history")!.canAdd = true;
    this.patientRecordService.getPatientFamilyMembers().subscribe(
      (members) => {
        this.records.find((x) => x.id === "family_history")!.attrForAction = [
          { id: "name", text: this.translate("medicalRecord.name") },
          { id: "relationship", text: this.translate("medicalRecord.relationship") },
          { id: "gender", text: this.translate("medicalRecord.Gender") },
          { id: "ageString", text: this.translate("medicalRecord.Age") },
          { id: "deceasedBoolean", text: this.translate("medicalRecord.deceased") },
          { id: "reasonCode", text: this.translate("medicalRecord.reason") },
          { id: "condition", text: this.translate("medicalRecord.condition") },
          { id: "note", text: this.translate("medicalRecord.notes") },
        ];
        (this.records as any[]).find((x) => x.id === "family_history").items = members.map((member) => {
          return {
            id: member.id,
            name: member.relationship.text,
            value: null,
            date: null,
            dateStr: member.date,
            data: member,
          };
        });
      },
      (err) => {
        this.toastService.generalErrorMessage();
      }
    );
  }

  saveFamilyMember(member: any) {
    this.isInfoPopupVisible = !this.isInfoPopupVisible;
    this.patientRecordService.addPatientFamilyMember(member).subscribe(
      (x) => {
        if (!this.helpers.responseHasErrors(x, true)) {
          this.getPatientFamilyMembers();
          this.toastService.successMessage(this.translationService.instant("medicalRecord.createSuccessFamilyMember"));
        }
      },
      (err) => {
        this.toastService.generalErrorMessage();
      }
    );
  }

  getPatientAllergies() {
    if (this.showCategories && !this.showCategories.includes("allergies")) return;
    this.records.find((x) => x.id === "allergies")!.action = true;
    this.records.find((x) => x.id === "allergies")!.canAdd = true;
    this.patientRecordService.getPatientAllergies().subscribe(
      (allergies) => {
        this.records.find((x) => x.id === "allergies")!.attrForAction = [
          { id: "code", text: this.translate("medicalRecord.allergy") },
          { id: "category", text: this.translate("medicalRecord.Category") },
          { id: "criticality", text: this.translate("medicalRecord.criticality") },
          { id: "note", text: this.translate("medicalRecord.notes") },
        ];
        (this.records as any[]).find((x) => x.id === "allergies").items = allergies.map((allergy) => {
          return {
            id: allergy.id,
            name: allergy.code!.text || (allergy as any).code.coding[0].display,
            value: null,
            date: null,
            dateStr: allergy.meta.lastUpdated,
            data: allergy,
          };
        });
      },
      (err) => {
        this.toastService.generalErrorMessage();
      }
    );
  }

  saveAllergy(allergy: any) {
    this.isInfoPopupVisible = !this.isInfoPopupVisible;
    this.patientRecordService.addPatientAllergy(allergy).subscribe(
      (x) => {
        if (!this.helpers.responseHasErrors(x, true)) {
          this.getPatientAllergies();
          this.toastService.successMessage(this.translationService.instant("medicalRecord.createSuccessAllergy"));
        }
      },
      (err) => {
        this.toastService.generalErrorMessage();
      }
    );
  }

  getPatientConditions() {
    if (this.showCategories && !this.showCategories.includes("conditions")) return;
    this.records.find((x) => x.id === "conditions")!.action = true;
    this.records.find((x) => x.id === "conditions")!.canAdd = true;
    this.patientRecordService.getPatientConditions().subscribe(
      (conditions) => {
        this.records.find((x) => x.id === "conditions")!.attrForAction = [
          { id: "code", text: this.translate("medicalRecord.condition") },
          { id: "clinicalStatus", text: this.translate("medicalRecord.clinicalStatus") },
          { id: "onsetPeriod", text: this.translate("medicalRecord.onSetDate") },
          { id: "note", text: this.translate("medicalRecord.notes") },
          { id: "extension", text: this.translate("medicalRecord.Attachments") },
        ];
        (this.records as any[]).find((x) => x.id === "conditions").items = conditions.map((condition) => {
          return {
            id: condition.id,
            name: condition.code!.text || (condition as any).code.coding[0].display,
            value: null,
            date: null,
            dateStr: condition.meta.lastUpdated,
            data: condition,
          };
        });
      },
      (err) => {
        this.toastService.generalErrorMessage();
      }
    );
  }

  saveCondition(condition: any) {
    this.isInfoPopupVisible = !this.isInfoPopupVisible;
    this.patientRecordService.addPatientCondition(condition).subscribe(
      (x) => {
        if (!this.helpers.responseHasErrors(x, true)) {
          this.getPatientConditions();
          this.toastService.successMessage(this.translationService.instant("medicalRecord.createSuccessCondition"));
        }
      },
      (err) => {
        this.toastService.generalErrorMessage();
      }
    );
  }

  getPatientReports() {
    if (this.showCategories && !this.showCategories.includes("reports")) return;
    this.records.find((x) => x.id === "reports")!.action = true;
    this.records.find((x) => x.id === "reports")!.canAdd = true;
    this.patientRecordService.getPatientReports().subscribe(
      (reports) => {
        this.records.find((x) => x.id === "reports")!.attrForAction = [
          { id: "code", text: this.translate("medicalRecord.title") },
          { id: "effectiveDateTime", text: this.translate("medicalRecord.date") },
          { id: "extension", text: this.translate("medicalRecord.Attachments") },
        ];
        (this.records as any[]).find((x) => x.id === "reports").items = reports.map((report) => {
          return {
            id: report.id,
            name: report.code.text || (report as any).code.coding[0].display,
            value: null,
            date: null,
            dateStr: moment(report.effectiveDateTime).format("YYYY-MM-DD"),
            data: report,
          };
        });
      },
      (err) => {
        this.toastService.generalErrorMessage();
      }
    );
  }

  saveReport(report: any) {
    this.isInfoPopupVisible = !this.isInfoPopupVisible;
    this.patientRecordService.addPatientReport(report).subscribe(
      (x) => {
        if (!this.helpers.responseHasErrors(x, true)) {
          this.getPatientReports();
          this.toastService.successMessage(this.translationService.instant("medicalRecord.createSuccessTest"));
        }
      },
      (err) => {
        this.toastService.generalErrorMessage();
      }
    );
  }

  getPatientMedications() {
    if (this.showCategories && !this.showCategories.includes("medication")) return;
    this.records.find((x) => x.id === "medication")!.action = true;
    this.records.find((x) => x.id === "medication")!.canAdd = true;
    this.patientRecordService.getPatientMedication().subscribe(
      (medications) => {
        this.records.find((x) => x.id === "medication")!.attrForAction = [
          { id: "medicationCodeableConcept", text: this.translate("medicalRecord.medication") },
          { id: "dosageInstruction", text: this.translate("medicalRecord.period") },
        ];
        (this.records as any[]).find((x) => x.id === "medication").items = medications.map((medication) => {
          return {
            id: medication.id,
            name:
              medication.medicationCodeableConcept!.text ||
              (medication as any).medicationCodeableConcept.coding[0].display,
            value: null,
            date: null,
            dateStr: (medication as any).dosageInstruction[0].timing.repeat.boundsPeriod.start,
            data: medication,
          };
        });
      },
      (err) => {
        this.toastService.generalErrorMessage();
      }
    );
  }

  saveMedication(medication: any) {
    this.isInfoPopupVisible = !this.isInfoPopupVisible;
    this.patientRecordService.addPatientMedication(medication).subscribe(
      (x) => {
        if (!this.helpers.responseHasErrors(x, true)) {
          this.getPatientMedications();
          this.toastService.successMessage(this.translationService.instant("medicalRecord.createSuccessMedication"));
        }
      },
      (err) => {
        this.toastService.generalErrorMessage();
      }
    );
  }

  getPatientSocialHistory() {
    if (this.showCategories && !this.showCategories.includes("social_history")) return;
    this.records.find((x) => x.id === "social_history")!.action = true;
    this.records.find((x) => x.id === "social_history")!.canAdd = true;
    this.patientRecordService.getPatientSocialHistory().subscribe(
      (socialHistories) => {
        this.records.find((x) => x.id === "social_history")!.attrForAction = [
          { id: "code", text: this.translate("medicalRecord.observation") },
          { id: "effectiveDateTime", text: this.translate("medicalRecord.date") },
        ];
        (this.records as any[]).find((x) => x.id === "social_history").items = socialHistories.map((socialHistory) => {
          return {
            id: socialHistory.id,
            name: socialHistory.code.text,
            value: null,
            date: null,
            dateStr: moment(socialHistory.effectiveDateTime).format("YYYY-MM-DD"),
            data: socialHistory,
          };
        });
      },
      (err) => {
        this.toastService.generalErrorMessage();
      }
    );
  }

  saveSocialHistory(socialHistory: any) {
    this.isInfoPopupVisible = !this.isInfoPopupVisible;
    this.patientRecordService.addPatientSocialHistory(socialHistory).subscribe(
      (x) => {
        if (!this.helpers.responseHasErrors(x, true)) {
          this.getPatientSocialHistory();
          this.toastService.successMessage(this.translationService.instant("medicalRecord.createSuccessSocialHistory"));
        }
      },
      (err) => {
        this.toastService.generalErrorMessage();
      }
    );
  }

  getPatientHospitalizations() {
    if (this.showCategories && !this.showCategories.includes("hospitalizations")) return;
    this.records.find((x) => x.id === "hospitalizations")!.action = true;
    this.records.find((x) => x.id === "hospitalizations")!.canAdd = true;
    this.patientRecordService.getEncounters().subscribe(
      (res) => {
        this.records.find((x) => x.id === "hospitalizations")!.attrForAction = [
          { id: "period", text: this.translate("medicalRecord.period") },
          { id: "reason", text: this.translate("medicalRecord.reason") },
          { id: "extension", text: this.translate("medicalRecord.Attachments") },
        ];
        (this.records as any[]).find((x) => x.id === "hospitalizations").items = res.map((item) => {
          return {
            id: item.id,
            name: item.reason![0].text || item.reason?.[0]?.coding?.[0].display,
            value: null,
            date: null,
            dateStr: null,
            period: item.period,
            data: item,
          };
        });
      },
      (err) => {
        this.toastService.generalErrorMessage();
      }
    );
  }

  saveHospitalization(resource: any) {
    this.isInfoPopupVisible = !this.isInfoPopupVisible;
    this.patientRecordService.addEncounter(resource).subscribe(
      (x) => {
        if (!this.helpers.responseHasErrors(x, true)) {
          this.getPatientHospitalizations();
          this.toastService.successMessage(
            this.translationService.instant("medicalRecord.createSuccessHospitalization")
          );
        }
      },
      (err) => {
        this.toastService.generalErrorMessage();
      }
    );
  }

  getFilteredRecordItems(items: Array<any>, id: string) {
    // if (id !== "general" && id !== "blood") return items;
    return items; // allagi wste na fainontai sto ui ola ta categories

    // const temp = items.filter((x) => this.observationCategories.some((y) => y.code === x.id));

    // const sorted = temp.sort((a, b) => {
    //   if (a.date < b.date) return 1;
    //   if (a.date > b.date) return -1;
    //   return 0;
    // });

    // return sorted;
  }

  async getObservationCategories() {
    try {
      this.observationCategories = (await this.medicalRecordService
        .getObservationCategories(this.patientId)
        .toPromise()) as any;
    } catch (err) {
      this.toastService.generalErrorMessage();
    }
  }

  async getExcludedObservationCategories() {
    try {
      await this.getObservationCategories();
      const allCategories = await this.medicalRecordService.getMedicalRecordCategories().toPromise();

      const temp: IObservationCategory[] = [];

      allCategories!.records.forEach((category) => {
        if (category.id === "general" || category.id === "blood") {
          category.items!.forEach((item: any) => {
            temp.push({
              id: item.id,
              category: category.id as string,
              code: item.id,
              label: item.id,
            });
          });
        }
      });

      this.excludedCategories = [];

      if (this.observationCategories.length !== 0) {
        this.excludedCategories = temp.filter((category) => {
          // return !this.observationCategories.some((selected) => category.id === selected.code);
          return false;
        });
      } else this.excludedCategories = []; // temp;
    } catch (err) {
      this.toastService.generalErrorMessage();
    }
  }

  async addCategories() {
    try {
      this.formSubmitting = true;
      await this.medicalRecordService.addObservationCategory(this.patientId, this.selectedCategories).toPromise();
      await this.getExcludedObservationCategories();
      await this.getObservations();
    } catch (err) {
      this.formSubmitting = false;
      this.toastService.generalErrorMessage();
    }
    this.formSubmitting = false;
    this.isInfoPopupVisible = false;
  }

  async removeCategory(x: string) {
    try {
      await this.medicalRecordService.deleteObservationCategory(this.patientId, x).toPromise();
      await this.getObservations();
      await this.getExcludedObservationCategories();
    } catch (error) {
      this.toastService.generalErrorMessage();
    }
  }

  openObservationChart(item: IRecordItem) {
    if (!this.patientRestrictedAccess && (item.code === "3141-9-manual" || item.code === "3141-9")) {
      this.googleAnalyticsService.eventEmitter("view_weight_progress_graph", "engagement", "patient");
    }
    this.chartInput = item;
    // console.log(item);
    this.showChartPopup = true;
  }

  async editItem(event: any, category: string, item: any) {
    event.stopPropagation();
    this.editComponentCategory = category;
    this.selectedItemForEdit = item;
    this.isEditPopupVisible = true;
  }

  async saveEditedItem(item: any) {
    try {
      let result;
      switch (this.editComponentCategory) {
        case "allergies":
          result = await this.patientRecordService.editItem("allergies", item.id, item)!.toPromise();
          this.getPatientAllergies();
          break;
        case "vaccines":
          result = await this.patientRecordService.editItem("vaccines", item.id, item)!.toPromise();
          this.getPatientVaccines();
          break;
        case "procedures":
          result = await this.patientRecordService.editItem("procedures", item.id, item)!.toPromise();
          this.getPatientProcedures();
          break;
        case "medication":
          result = await this.patientRecordService.editItem("medication", item.id, item)!.toPromise();
          this.getPatientMedications();
          break;
        case "social_history":
          result = await this.patientRecordService.editItem("social_history", item.id, item)!.toPromise();
          this.getPatientSocialHistory();
          break;
        case "family_history":
          result = await this.patientRecordService.editItem("family_history", item.id, item)!.toPromise();
          this.getPatientFamilyMembers();
          break;
        case "conditions":
          result = await this.patientRecordService.editItem("conditions", item.id, item)!.toPromise();
          this.getPatientConditions();
          break;
        case "reports":
          result = await this.patientRecordService.editItem("reports", item.id, item)!.toPromise();
          this.getPatientReports();
          break;
        case "hospitalizations":
          result = await this.patientRecordService.editItem("hospitalizations", item.id, item)!.toPromise();
          this.getPatientHospitalizations();
          break;
      }
      this.toastService.successMessage(this.translationService.instant("medicalRecord.editSuccess"));
      this.isEditPopupVisible = false;
      this.selectedItemForEdit = null;
    } catch (err) {
      this.isEditPopupVisible = false;
      this.selectedItemForEdit = null;
      this.toastService.generalErrorMessage();
    }
  }

  async removeItem(event: any, category: string, itemId: string) {
    event.stopPropagation();
    try {
      await this.patientRecordService.deleteItem(category, itemId)!.toPromise();
      this.toastService.successMessage(this.translationService.instant("medicalRecord.deleteSuccess"));

      switch (category) {
        case "allergies":
          this.getPatientAllergies();
          break;
        case "vaccines":
          this.getPatientVaccines();
          break;
        case "procedures":
          this.getPatientProcedures();
          break;
        case "medication":
          this.getPatientMedications();
          break;
        case "social_history":
          this.getPatientSocialHistory();
          break;
        case "family_history":
          this.getPatientFamilyMembers();
          break;
        case "conditions":
          this.getPatientConditions();
          break;
        case "reports":
          this.getPatientReports();
          break;
        case "hospitalizations":
          this.getPatientHospitalizations();
          break;
      }
    } catch (err) {
      this.toastService.generalErrorMessage();
    }
  }

  toggleEditParticipationPopup() {
    this.showEditParticipationPopup = true;
  }

  async saveParticipationInfo(item: any) {
    try {
      this.formSubmitting = true;
      let result: any;
      if (this.patientParticipationData) {
        result = await this.patientRecordService.editParticipationInfo(item).toPromise();
      } else {
        result = await this.patientRecordService.addParticipationInfo(item).toPromise();
      }
      this.getPatientParticipationData();
    } catch (err) {
      this.formSubmitting = false;
      this.toastService.generalErrorMessage();
    }
    this.formSubmitting = false;
    this.showEditParticipationPopup = false;
  }

  getPatientParticipationData() {
    this.patientRecordService.getParticipationInfo().subscribe(
      (data: any) => {
        if (data && !data.status) {
          this.patientParticipationData = data;
        } else if (data && data.status) {
          throw new Error(data.message || data.statusText);
        } else {
          this.patientParticipationData = null;
        }
      },
      (err) => {
        this.toastService.generalErrorMessage();
      }
    );
  }

  async removeObservation(id: string) {
    try {
      const result = await this.patientRecordService.deleteLastObservation(id).toPromise();
      if (result) {
        this.toastService.infoMessage(this.translationService.instant("medicalRecord.deleteSuccess"));
        this.getObservations();
        if (this.isObservationListVisible) {
          this.showList(this.observationList[0].component.code.coding[0].code);
        }
      }
    } catch (error) {
      console.log(error);
      this.toastService.generalErrorMessage();
    }
  }

  showList(code: any) {
    this.isObservationListVisible = true;
    this.httpClient
      .get(
        `${environment.serverPath}/api/patient-record/careplan/activities/get-observations-by-type/${this.patientId}?observation=${code}`
      )
      .subscribe((res: any) => {
        this.observationList = res.map((z: any) => ({
          effectiveDateTime: z.effectiveDateTime,
          component: z.component.find((x: any) => x.code.coding[0].code === code),
          id: z.id,
        }));
        this.observationList = this.observationList.sort(
          (a: any, b: any) => moment(b.effectiveDateTime).unix() - moment(a.effectiveDateTime).unix()
        );
      });
  }

  closeObservationListPopup() {
    this.isObservationListVisible = false;
    this.observationList = [];
  }
}
